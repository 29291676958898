import { createStore } from "vuex";
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../main";

const store = createStore({
  state() {
    return {
      contactList: [],
      propertyList: [],
      userMarketList: [],
      stripeRole: null,
      userInfos: {}
    };
  },
  mutations: {

    setUserMarketList(state, payload) {
      state.userMarketList = JSON.parse(payload.JSONdatas);
    },
    addUserMarket(state, payload) {
      state.userMarketList.push(payload)
    },
    setStripeRole(state,payload) {
      state.stripeRole = payload
    },
    setUserInfos(state, payload) {
      state.userInfos = payload
    },
  },
  actions: {
    async getCustomClaimRole(context) {
      const auth = getAuth();
      auth.currentUser?.reload();
      //await auth.currentUser?.getIdTokenResult(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);
      auth.currentUser?.reload();
      context.commit('setStripeRole', decodedToken?.claims.stripeRole)
    },
    async realtimeRetrieveMarkets(context, uid) {
      
      try {
        const docRef = doc(db, "userMarketLists", uid);
        onSnapshot(docRef, (doc) => {
          const datas = doc.data();
          context.commit("setUserMarketList", datas);
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des marchés en temps réel : ", error);
        // Vous pouvez ajouter une action contextuelle ici, comme:
        // context.commit("setError", error.message);
        // ou
        // context.commit("setUserMarketList", null);
      }
    },
    async saveContactList(context) {
      const data = JSON.stringify(context.state.contactList);
      if (data) {
        try {
          await setDoc(doc(db, "contactLists", getAuth().currentUser.uid), {
            JSONdatas: data,
            lastModified: Date.now(),
          });
        } catch (err) {
          console.error("save contact error", err);
          alert("Une erreur est survenue: ", err);
        }
      }
    },
    async savePropertyList(context) {
      const data = JSON.stringify(context.state.propertyList);
      if (data) {
        try {
          await setDoc(doc(db, "propertyLists", getAuth().currentUser.uid), {
            JSONdatas: data,
            lastModified: Date.now(),
          });
        } catch (err) {
          console.error("save property error", err);
          alert("Une erreur est survenue: ", err);
        }
      }
    },
    async addMarket(context, datas) {
      context.commit("addUserMarket", datas);
    },
    async saveMarketList(context, uid) {
      const data = JSON.stringify(context.state.userMarketList);
      if (data) {
        try {
          await setDoc(doc(db, "userMarketLists", uid), {
            JSONdatas: data,
            lastModified: Date.now(),
          });
        } catch (err) {
          console.error("save market error", err);
          alert("Une erreur est survenue: ", err);
        }
      }
    },
    async retrieveInfos(context) {
      const currentUser = getAuth().currentUser
      const docRef = doc(db, "userInfos", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          const datas = docSnap.data();
          context.commit('setUserInfos', datas.JSONdatas)
      } else if(currentUser.providerData){
        context.commit('setUserInfos', currentUser.providerData[0])
      } else {
          console.log("No such document!");
      }
  },
  },
  getters: {
    getMatchingContacts(propertyID) {
      console.log(propertyID);
    },
    getMatchingProperties(contactID) {
      console.log(contactID);
    },
    getContactFromID: (state) => (id) => {
      return state.contactList.find((item) => item.id == id);
    },
    getPropertyFromID: (state) => (id) => {
      return state.propertyList.find((item) => item.id == id);
    },
    getMarketFromID: (state) => (id) => {
      return state.userMarketList.find((item) => item.id == id);
    },
  },
});

export default store;
