<template>
  <router-view/>
</template>

<style>
body {
  background: rgb(10, 17, 29);

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #51a0ef;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.wide {
  width: 100% !important;
}

</style>
