<template>
  <div>
    <table v-if="final">
      <tr>
        <td>FOR</td>
        <!--force : porter choses lourdes degats cac-->
        <td>INT</td>
        <!-- intelligence : utilisation machines et appareils-->
        <td>DEX</td>
        <!-- dexterité : reussite cac-->
        <td>PRE</td>
        <!--precision : reussite distance / attenuation dispersion-->
        <td>CON</td>
        <!-- constitution : les points de vie des joueurs seront CON x 2-->
        <td>VIT</td>
        <!-- vitesse : initiative et determination de l'ordre du tour -->
        <td>PSY</td>
        <!-- psychisme : puissance pouvoirs / resist corruption-->
      </tr>
      <tr>
        <td>{{ caracs.force }}</td>
        <td>{{ caracs.intelligence }}</td>
        <td>{{ caracs.dexterite }}</td>
        <td>{{ caracs.precision }}</td>
        <td>{{ caracs.constitution }}</td>
        <td>{{ caracs.rapidite }}</td>
        <td>{{ caracs.psychisme }}</td>
      </tr>
    </table>
    <div>
      <div v-for="arme in armesPerso" :key="arme.nom">
        <h3>{{ arme.nom }}</h3>
        <div class="flex-wrap arme">
          <div>
            <span>Cadence </span>
            <b v-if="arme.baseCadence">{{ arme.baseCadence }}</b>
            <b v-if="arme.baseCadence && arme.Dcadence">+</b>
            <b v-if="arme.Dcadence">{{ arme.Dcadence }}D6</b>
          </div>
          <div>
            <span>dispersion </span> <b>{{ arme.dispersion }}</b>
          </div>
          <div>
            <span>Portée</span> <b>{{ arme.portee }}</b
            >m
          </div>
          <div>
            <span>Poids </span> <b>{{ arme.poids }}</b>
          </div>

          <div>
            <span>Chargeur </span> <b>{{ arme.chargeur }}</b>
          </div>
          <div>
            <span>Munitions : </span> <b>{{ arme.munitions }}</b>
          </div>
        </div>
      </div>
    </div>
    <div class="hand"  v-if="final">
      <button @click="pickRandomCard()">Pioche</button>
      <div class="cardo" v-for="(cardo, index) in hand" :key="index">
        {{ cardo[0] }}
      </div>
    </div>
  </div>
  <shop />
</template>
<script>
import Shop from "./ShopComp.vue";
export default {
  /**
   *
   * Reussite seuil de base cible statique à distance maximale : 13
   *
   * +10m : +1 au seuil
   * -10m : -1 au seuil
   * Mouvement durant visée (tireur ou cible)  : +1, +X suivant l'intnsité du mouvement
   * Conditions difficiles, nuit, brouillard, foule etc : +1, +X
   *
   *
   */
  components: {
    shop: Shop,
  },
  data() {
    return {
      caracs: {
        force: 0,
        intelligence: 0,
        dexterite: 0,
        precision: 0,
        constitution: 0,
        rapidite: 0,
        psychisme: 0,
      },
      cartes: [1, 1, 1, 2, 2, 3, 3, 4, 5, 6],
      hand: [],
    };
  },
  methods: {
    pickRandomCard() {
      if (this.hand.length < 4) {
        const ranDonum = Math.floor(Math.random() * this.cartes.length);
        let drawn = this.cartes.splice(ranDonum, 1);
        this.hand.push(drawn);
      }
    },
  },
};
</script>
<style lang="scss">
.cardo {
  height: 70px;
  width: 50px;
  text-align: center;
  line-height: 70px;
  display: inline-block;
  border: 1px solid #a9d7ff;
  margin: 2px;
  color: #a9d7ff;
  border-radius: 5px;
  font-size: 20px;
}
h3 {
  padding: 0;
  margin: 0;
}

.arme {
  margin-bottom: 20px;

  div {
    min-width: 140px;
  }

  b {
    padding: 3px;
  }

  .wide b {
    display: inline;
  }
}
</style>
