<template>
  <div>
    <div class="shop-section">
      <!-- Armes Pagination -->
      <!-- Panier -->
      <p>Panier : <span>{{ calculerTotal() }}</span></p>
      
      <div v-for="(item, index) in panier" :key="index" class="panier-item">
        {{ item.nom }} - Type : {{ item.type }}
        <button @click="retirerDuPanier(index)">Retirer</button>
      </div>
      <h3>Armes</h3>
      <div class="arme-container">
        <div
          v-for="(arme, index) in paginatedArmes"
          :key="index"
          class="arme-card"
        >
          <div class="name">{{ arme.nom }}</div>
          <p><b>Type:</b> {{ arme.type }}</p>
          <p><b>Cadence:</b> {{ arme.baseCadence }} + {{ arme.Dcadence }}D6</p>
          <p><b>Poids:</b> {{ arme.poids }}</p>
          <p><b>Dispersion:</b> {{ arme.dispersion }}</p>
          <p><b>Portée:</b> {{ arme.portee }}m</p>
          <p><b>Chargeur:</b> {{ arme.chargeur }}</p>
          <p><b>munitions:</b> {{ arme.munitions }}</p>
          <button @click="ajouterAuPanier('arme', arme)" class="wide">ajouter</button>
        </div>

      </div>
      <!-- Pagination Controls for Armes -->
      <div class="pagination">
        <button @click="pageArme--" :disabled="pageArme === 1">&#60;</button>
        <span>Page {{ pageArme }} sur {{ totalPagesArme }}</span>
        <button @click="pageArme++" :disabled="pageArme === totalPagesArme">
          &#62;
        </button>
      </div>

      <!-- Munitions Pagination -->
      <h3>Munitions</h3>
      <div class="munition-container">
        <div
          v-for="(munition, index) in paginatedMunitions"
          :key="index"
          class="munition-card"
        >
          <div class="name">{{ munition.nom }}</div>
          <p><b>Calibre:</b> {{ munition.calibre }}</p>
          <p><b>Dégâts:</b> {{ munition.degats }}</p>
          <p><b>Pénétration: </b> {{ munition.fPene }}</p>
          <p><b>Prix / unité:</b> {{ munition.prix }}</p>
          <button @click="ajouterAuPanier('munition', munition)" class="wide">
            ajouter
          </button>
        </div>
      </div>
      <!-- Pagination Controls for Munitions -->
      <div class="pagination">
        <button @click="pageMunition--" :disabled="pageMunition === 1">
          &#60;
        </button>
        <span>Page {{ pageMunition }} sur {{ totalPagesMunition }}</span>
        <button
          @click="pageMunition++"
          :disabled="pageMunition === totalPagesMunition"
        >
          &#62;
        </button>
      </div>

      <!-- Armures Pagination -->
      <h3>Armures</h3>
      <div class="armure-container">
        <div
          v-for="(armure, index) in paginatedArmures"
          :key="index"
          class="armure-card"
        >
          <div class="name">{{ armure.nom }}</div>
          <p><b>Blindage:</b> {{ armure.blindage }}</p>
          <p><b>Résistance:</b> {{ armure.resistance }}</p>
          <p><b>encombrement:</b> {{ armure.poids }}</p>
          <p><b>prix:</b> {{ armure.prix }}</p>
          <button @click="ajouterAuPanier('armure', armure)" class="wide">ajouter</button>
        </div>
      </div>
      <!-- Pagination Controls for Armures -->
      <div class="pagination">
        <button @click="pageArmure--" :disabled="pageArmure === 1">
          &#60;
        </button>
        <span>Page {{ pageArmure }} sur {{ totalPagesArmure }}</span>
        <button
          @click="pageArmure++"
          :disabled="pageArmure === totalPagesArmure"
        >
          &#62;
        </button>
      </div>

      <!-- Panier -->
      <h3>Panier</h3>
      <div v-for="(item, index) in panier" :key="index" class="panier-item">
        {{ item.nom }} - Type : {{ item.type }}
        <button @click="retirerDuPanier(index)">Retirer</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      armes: [
        {
          nom: "AJ 349",
          type: "Pistolet mitrailleur",
          Dcadence: 1,
          baseCadence: 0,
          chargeur: "Octo",
          poids: 6,
          dispersion: 12,
          munitions: "9x19mm Parabellum",
          portee: 40,
          note: "Mitrilleur de base",
          special: "Se bloque au bout de 3 chargeurs",
          description:
            "Conçu par Cheyenne avant la catastrophe nucléaire, le AJ 349 est devenu une arme courante des milices post-apocalyptiques. Robuste et fiable, mais sujette à des blocages après un usage intensif.",
          prix: 750,
        },
        {
          nom: "Suntara Mk VII",
          type: "Fusil mitrailleur",
          Dcadence: 3,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 15,
          dispersion: 8,
          munitions: "7.62x51mm NATO",
          portee: 90,
          note: "Fusil lourd, puissant mais lourd à manipuler",
          special: "+1 à la dispersion après chaque tir prolongé",
          description:
            "Le Mk VII est un héritage direct de la période pré-apocalypse, souvent utilisé pour la défense des bunkers et des colonies. Sa puissance en fait une arme respectée, mais son poids le rend difficile à manier.",
          prix: 1500,
        },
        {
          nom: "Nova Astra 215",
          type: "Carabine",
          Dcadence: 2,
          baseCadence: 0,
          chargeur: "Hexa",
          poids: 8,
          dispersion: 10,
          munitions: "6.5mm Creedmoor",
          portee: 60,
          note: "Carabine standard pour les engagements à moyenne portée",
          special: "+1 à la portée si utilisé par un tireur expert",
          description:
            "La Nova Astra 215 est réputée pour sa précision, utilisée par les éclaireurs et chasseurs dans les terres dévastées. Connue pour être fiable même après des années d'errance dans des conditions extrêmes.",
          prix: 1200,
        },
        {
          nom: "Voltac X9",
          type: "Pistolet",
          Dcadence: 1,
          baseCadence: 1,
          chargeur: "Quadra",
          poids: 3,
          dispersion: 14,
          munitions: ".45 ACP",
          portee: 25,
          note: "Petit, maniable, mais dispersion élevée",
          special: "+1 à la DEX pour les utilisateurs avec plus de 12 en DEX",
          description:
            "Le Voltac X9 est l'arme de choix des survivants des rues. Compact et facile à dissimuler, il a été adopté par les factions criminelles des villes en ruines malgré sa faible précision.",
          prix: 450,
        },
        {
          nom: "AJ 400",
          type: "Pistolet mitrailleur",
          Dcadence: 2,
          baseCadence: 0,
          chargeur: "Hexa",
          poids: 7,
          dispersion: 11,
          munitions: "9x19mm Parabellum",
          portee: 50,
          note: "Plus de cadence que l'AJ 349, mais plus lourd",
          special: "Se bloque après 4 chargeurs",
          description:
            "Version améliorée du AJ 349, l'AJ 400 offre une cadence de tir supérieure. Sa popularité a grandi dans les zones frontalières, où des combats rapides et intenses sont monnaie courante.",
          prix: 850,
        },
        {
          nom: "Suntara Mk X",
          type: "Fusil mitrailleur",
          Dcadence: 4,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 18,
          dispersion: 6,
          munitions: "7.62x54mmR",
          portee: 100,
          note: "Cadence de tir élevée, mais nécessite une bonne stabilité",
          special: "+1 à la FOR pour le maniement avec moins de dispersion",
          description:
            "Le Mk X est conçu pour les longues batailles. Utilisé par les gardes des cités fortifiées, il incarne la puissance et la protection dans un monde devenu chaotique.",
          prix: 1800,
        },
        {
          nom: "Nova Astra 300",
          type: "Carabine",
          Dcadence: 3,
          baseCadence: 1,
          chargeur: "Hexa",
          poids: 9,
          dispersion: 9,
          munitions: ".300 Winchester Magnum",
          portee: 75,
          note: "Carabine pour longues distances avec grande stabilité",
          special: "Bonus de précision si utilisé avec une lunette",
          description:
            "La Nova Astra 300 est la favorite des tireurs d'élite, souvent utilisée pour défendre les points stratégiques dans les no man's land irradiés. Elle excelle dans les engagements à longue distance.",
          prix: 1350,
        },
        {
          nom: "Voltac T7",
          type: "Pistolet",
          Dcadence: 1,
          baseCadence: 1,
          chargeur: "Octo",
          poids: 4,
          dispersion: 12,
          munitions: "9x18mm Makarov",
          portee: 30,
          note: "Pistolet robuste avec une bonne cadence",
          special: "+1 à la PSY pour les utilisateurs avec moins de 10 en FOR",
          description:
            "Arme préférée des factions religieuses mystiques, le Voltac T7 a souvent été modifié avec des symboles sacrés pour les protéger des aberrations nées des radiations. Un pistolet pour les croyants en quête de pouvoir.",
          prix: 500,
        },
        {
          nom: "AJ 500",
          type: "Pistolet mitrailleur",
          Dcadence: 3,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 9,
          dispersion: 10,
          munitions: "5.7x28mm FN",
          portee: 55,
          note: "Pistolet mitrailleur avancé avec grande capacité de chargeur",
          special: "Se bloque après 5 chargeurs",
          description:
            "Modèle haut de gamme de la série AJ, le 500 est prisé par les groupes d'élite pour ses capacités de tir soutenu. Parfait pour les escarmouches rapides dans les zones urbaines dévastées.",
          prix: 1200,
        },
        {
          nom: "Suntara Mk XX",
          type: "Fusil mitrailleur",
          Dcadence: 5,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 20,
          dispersion: 5,
          munitions: ".338 Lapua Magnum",
          portee: 120,
          note: "Puissance de feu maximale, mais nécessite une grande force pour être manié",
          special: "-1 à la dispersion si utilisé en position allongée",
          description:
            "Le Suntara Mk XX est l’arme ultime des groupes de combat lourd. Conçu pour les engagements prolongés contre des forces organisées, il est souvent utilisé dans les batailles pour les ressources vitales.",
          prix: 2000,
        },
        {
          nom: "Tiberion S320",
          type: "Carabine",
          Dcadence: 3,
          baseCadence: 0,
          chargeur: "Hexa",
          poids: 9,
          dispersion: 8,
          munitions: "5.56x45mm NATO",
          portee: 80,
          note: "Carabine légère et polyvalente",
          special: "+1 en PRE pour les tireurs précis",
          description:
            "Carabine couramment utilisée par les patrouilles dans les terres irradiées, la Tiberion S320 est réputée pour sa légèreté et son efficacité dans des environnements variés.",
          prix: 950,
        },
        {
          nom: "Voltac V5",
          type: "Pistolet",
          Dcadence: 1,
          baseCadence: 1,
          chargeur: "Hexa",
          poids: 3,
          dispersion: 13,
          munitions: ".40 S&W",
          portee: 27,
          note: "Pistolet fiable et simple",
          special: "Réduction de dispersion pour les personnages avec DEX > 10",
          description:
            "Ce modèle simple mais efficace est souvent utilisé par les groupes de survivants dans les colonies nomades. Facile à entretenir et à manier, il reste une arme de choix pour ceux qui privilégient la mobilité.",
          prix: 500,
        },
        {
          nom: "Nova Astra 600",
          type: "Carabine",
          Dcadence: 4,
          baseCadence: 1,
          chargeur: "Octo",
          poids: 12,
          dispersion: 7,
          munitions: ".308 Winchester",
          portee: 90,
          note: "Carabine de précision pour les grandes distances",
          special: "+2 à la portée avec une lunette de précision",
          description:
            "Utilisée par les tireurs d'élite post-apocalyptiques, la Nova Astra 600 est conçue pour les tirs de très longue distance dans des environnements ouverts et radioactifs.",
          prix: 1400,
        },
        {
          nom: "Suntara Mk II",
          type: "Fusil mitrailleur",
          Dcadence: 3,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 14,
          dispersion: 10,
          munitions: "7.62x39mm",
          portee: 85,
          note: "Fusil mitrailleur léger pour les opérations mobiles",
          special: "Réduction de dispersion en mouvement",
          description:
            "Modèle destiné aux forces de protection rapide, le Suntara Mk II est un favori des raids éclairs dans les zones dévastées par les radiations.",
          prix: 1300,
        },
        {
          nom: "AJ 600",
          type: "Pistolet mitrailleur",
          Dcadence: 4,
          baseCadence: 1,
          chargeur: "Pentagon",
          poids: 10,
          dispersion: 9,
          munitions: "10mm Auto",
          portee: 60,
          note: "Mitrailleur puissant mais lourd",
          special: "Se bloque après 6 chargeurs",
          description:
            "Le AJ 600 est une version lourde du pistolet mitrailleur, conçu pour les situations où la puissance de feu fait la différence entre la vie et la mort.",
          prix: 1100,
        },
        {
          nom: "Suntara Mk 18",
          type: "Fusil mitrailleur",
          Dcadence: 5,
          baseCadence: 2,
          chargeur: "Octo",
          poids: 22,
          dispersion: 6,
          munitions: ".50 BMG",
          portee: 150,
          note: "Fusil de grande puissance pour les combats lourds",
          special: "-2 à la dispersion si utilisé en position fixe",
          description:
            "Le Suntara Mk 18 est une véritable arme de destruction massive dans le contexte post-apocalyptique. Utilisée pour neutraliser les véhicules et fortifications, elle fait trembler les adversaires sur le champ de bataille.",
          prix: 2500,
        },
      ],
      munitions: [
        {
          nom: "9mm Parabellum",
          calibre: "9x19mm",
          degats: 2,
          fPene: 1,
          effet: "aucun",
          prix: 2,
        },
        {
          nom: "9mm Perçant le métal (PLM)",
          calibre: "9x19mm",
          degats: 1,
          fPene: 2,
          effet: "aucun",
          prix: 3,
        },
        {
          nom: "9mm Tête creuse (EXPLO)",
          calibre: "9x19mm",
          degats: 3,
          fPene: 0,
          effet: "hémorragie en cas de touche, -1pv / tour",
          prix: 3,
        },
        {
          nom: "9mm Biologique (BIO)",
          calibre: "9x19mm",
          degats: 1,
          fPene: 1,
          effet:
            "Chaque balle ajoute 1 niveau de charge biologique à la victime. À la CON atteinte, altération biologique aléatoire.",
          prix: 6,
        },
        {
          nom: "9mm Radioactive (NUCL)",
          calibre: "9x19mm",
          degats: 2,
          fPene: 1,
          effet:
            "Chaque balle ajoute 1 niveau de charge radioactive à la partie du corps touchée. À 6 niveaux, le membre tombe.",
          prix: 6,
        },
        {
          nom: "7.62mm NATO Parabellum",
          calibre: "7.62x51mm",
          degats: 4,
          fPene: 2,
          effet: "aucun",
          prix: 4,
        },
        {
          nom: "7.62mm Perçant le métal (PLM)",
          calibre: "7.62x51mm",
          degats: 3,
          fPene: 3,
          effet: "aucun",
          prix: 6,
        },
        {
          nom: "7.62mm Tête creuse (EXPLO)",
          calibre: "7.62x51mm",
          degats: 5,
          fPene: 0,
          effet: "hémorragie en cas de touche, -2pv / tour",
          prix: 6,
        },
        {
          nom: "7.62mm Biologique (BIO)",
          calibre: "7.62x51mm",
          degats: 2,
          fPene: 2,
          effet:
            "Chaque balle ajoute 1 niveau de charge biologique. À la CON atteinte, altération biologique aléatoire.",
          prix: 12,
        },
        {
          nom: "7.62mm Radioactive (NUCL)",
          calibre: "7.62x51mm",
          degats: 3,
          fPene: 2,
          effet:
            "Chaque balle ajoute 1 niveau de charge radioactive. À 6 niveaux, le membre tombe.",
          prix: 12,
        },
        {
          nom: "5.7mm FN Parabellum",
          calibre: "5.7x28mm",
          degats: 1,
          fPene: 1,
          effet: "aucun",
          prix: 2,
        },
        {
          nom: "5.7mm Perçant le métal (PLM)",
          calibre: "5.7x28mm",
          degats: 1,
          fPene: 2,
          effet: "aucun",
          prix: 3,
        },
        {
          nom: "5.7mm Tête creuse (EXPLO)",
          calibre: "5.7x28mm",
          degats: 2,
          fPene: 0,
          effet: "hémorragie en cas de touche, -1pv / tour",
          prix: 3,
        },
        {
          nom: "5.7mm Biologique (BIO)",
          calibre: "5.7x28mm",
          degats: 1,
          fPene: 1,
          effet:
            "Charge biologique s'accumule jusqu'à la CON, provoquant une altération biologique.",
          prix: 6,
        },
        {
          nom: "5.7mm Radioactive (NUCL)",
          calibre: "5.7x28mm",
          degats: 1,
          fPene: 1,
          effet:
            "Charge radioactive s'accumule jusqu'à 6 niveaux, causant la perte du membre.",
          prix: 6,
        },
        {
          nom: "6.5mm Creedmoor Parabellum",
          calibre: "6.5mm Creedmoor",
          degats: 3,
          fPene: 2,
          effet: "aucun",
          prix: 4,
        },
        {
          nom: "6.5mm Perçant le métal (PLM)",
          calibre: "6.5mm Creedmoor",
          degats: 2,
          fPene: 3,
          effet: "aucun",
          prix: 6,
        },
        {
          nom: "6.5mm Tête creuse (EXPLO)",
          calibre: "6.5mm Creedmoor",
          degats: 4,
          fPene: 0,
          effet: "hémorragie en cas de touche, -2pv / tour",
          prix: 6,
        },
        {
          nom: "6.5mm Biologique (BIO)",
          calibre: "6.5mm Creedmoor",
          degats: 2,
          fPene: 2,
          effet:
            "Charge biologique s'accumule jusqu'à la CON, provoquant une altération biologique.",
          prix: 12,
        },
        {
          nom: "6.5mm Radioactive (NUCL)",
          calibre: "6.5mm Creedmoor",
          degats: 3,
          fPene: 2,
          effet:
            "Charge radioactive s'accumule jusqu'à 6 niveaux sur une partie du corps, causant la perte du membre.",
          prix: 12,
        },
        {
          nom: ".338 Lapua Parabellum",
          calibre: ".338 Lapua Magnum",
          degats: 5,
          fPene: 3,
          effet: "aucun",
          prix: 6,
        },
        {
          nom: ".338 Perçant le métal (PLM)",
          calibre: ".338 Lapua Magnum",
          degats: 4,
          fPene: 4,
          effet: "aucun",
          prix: 9,
        },
        {
          nom: ".338 Tête creuse (EXPLO)",
          calibre: ".338 Lapua Magnum",
          degats: 6,
          fPene: -2,
          effet: "hémorragie en cas de touche, -3pv / tour",
          prix: 9,
        },
        {
          nom: ".338 Biologique (BIO)",
          calibre: ".338 Lapua Magnum",
          degats: 3,
          fPene: 3,
          effet:
            "Charge biologique s'accumule jusqu'à la CON, provoquant une altération biologique.",
          prix: 18,
        },
        {
          nom: ".338 Radioactive (NUCL)",
          calibre: ".338 Lapua Magnum",
          degats: 4,
          fPene: 3,
          effet:
            "Charge radioactive s'accumule jusqu'à 6 niveaux, causant la perte du membre.",
          prix: 18,
        },
      ],
      armures: {
  jambieres: [
    {
      nom: "Jambières légères en kevlar",
      blindage: 1,
      resistance: 5,
      poids: 2,
      description:
        "Jambières légères conçues pour offrir une protection contre les balles de petit calibre, tout en conservant une bonne mobilité. Elles sont rapidement détruites sous un tir soutenu.",
      prix: 75,
    },
    {
      nom: "Jambières Kevlar renforcé",
      blindage: 2,
      resistance: 7,
      poids: 4,
      description:
        "Jambières renforcées offrant une meilleure protection contre les projectiles lourds, avec une bonne capacité de résistance, mais peuvent être détruites si la résistance est épuisée.",
      prix: 125,
    },
    {
      nom: "Jambières blindées lourdes",
      blindage: 3,
      resistance: 10,
      poids: 6,
      description:
        "Jambières blindées lourdes offrant une protection maximale pour les combats intensifs, mais sont lourdes et réduisent la mobilité.",
      prix: 200,
    },
  ],

  brassards: [
    {
      nom: "Brassards légers en kevlar",
      blindage: 1,
      resistance: 5,
      poids: 1,
      description:
        "Brassards légers offrant une protection de base pour les bras tout en garantissant une mobilité optimale, mais se dégradent rapidement sous le feu soutenu.",
      prix: 50,
    },
    {
      nom: "Brassards Kevlar renforcé",
      blindage: 2,
      resistance: 6,
      poids: 2,
      description:
        "Brassards en kevlar renforcé offrant une protection accrue contre les tirs à moyenne distance, avec une bonne résistance avant d'être détruits.",
      prix: 100,
    },
    {
      nom: "Brassards blindés lourds",
      blindage: 3,
      resistance: 9,
      poids: 3,
      description:
        "Brassards lourds conçus pour offrir une protection maximale dans des situations de combat rapproché. Ils sont résistants mais assez lourds.",
      prix: 150,
    },
  ],

  plastrons: [
    {
      nom: "Plastron léger en kevlar",
      blindage: 1,
      resistance: 6,
      poids: 4,
      description:
        "Plastron léger offrant une bonne protection contre les tirs légers, mais une fois que la résistance tombe à zéro, les tirs passent directement à travers.",
      prix: 100,
    },
    {
      nom: "Plastron Kevlar renforcé",
      blindage: 2,
      resistance: 8,
      poids: 6,
      description:
        "Plastron renforcé offrant une protection solide contre les projectiles lourds. Il est conçu pour absorber plusieurs impacts avant d'être détruit.",
      prix: 200,
    },
    {
      nom: "Plastron blindé lourd",
      blindage: 3,
      resistance: 12,
      poids: 8,
      description:
        "Plastron lourd conçu pour résister à des attaques continues dans des combats intenses, offrant une protection maximale avant destruction.",
      prix: 300,
    },
  ],

  casques: [
    {
      nom: "Casque léger en kevlar",
      blindage: 1,
      resistance: 5,
      poids: 1,
      description:
        "Casque léger conçu pour protéger contre les balles de petit calibre. Il peut se briser rapidement sous des tirs répétés.",
      prix: 50,
    },
    {
      nom: "Casque Kevlar renforcé",
      blindage: 2,
      resistance: 6,
      poids: 3,
      description:
        "Casque renforcé offrant une meilleure protection contre les projectiles lourds, mais susceptible de se dégrader sous une attaque continue.",
      prix: 100,
    },
    {
      nom: "Casque lourd blindé",
      blindage: 3,
      resistance: 10,
      poids: 4,
      description:
        "Casque blindé conçu pour les zones de guerre intensives, offrant une excellente protection avant de s'effondrer sous de lourds assauts.",
      prix: 150,
    },
  ],

  boucliers: [
    {
      nom: "Bouclier anti-émeute",
      blindage: 2,
      resistance: 8,
      poids: 5,
      description:
        "Bouclier léger conçu pour bloquer les projectiles non létaux et les attaques de faible intensité. Il se détruit rapidement sous les tirs d'armes lourdes.",
      prix: 200,
    },
    {
      nom: "Bouclier balistique",
      blindage: 4,
      resistance: 12,
      poids: 8,
      description:
        "Bouclier balistique offrant une protection solide contre les tirs de gros calibre, conçu pour résister à des assauts continus.",
      prix: 400,
    },
    {
      nom: "Bouclier fortifié lourd",
      blindage: 6,
      resistance: 15,
      poids: 12,
      description:
        "Bouclier lourd et fortifié, utilisé dans les combats intenses. Il est capable de résister à un grand nombre de tirs avant d'être détruit.",
      prix: 600,
    },
  ],
},
      selectedArme: null,
      selectedMunition: null,
      selectedArmure: null,
      panier: [],
      // Pagination states
      pageArme: 1,
      pageMunition: 1,
      pageArmure: 1,
      itemsPerPage: 3,
    };
  },
  computed: {
    totalPagesArme() {
      return Math.ceil(this.armes.length / this.itemsPerPage);
    },
    paginatedArmes() {
      const start = (this.pageArme - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.armes.slice(start, end);
    },
    totalPagesMunition() {
      return Math.ceil(this.munitions.length / this.itemsPerPage);
    },
    paginatedMunitions() {
      const start = (this.pageMunition - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.munitions.slice(start, end);
    },
    totalPagesArmure() {
      const totalArmures = [
        ...this.armures.casques,
        ...this.armures.plastrons,
        ...this.armures.jambieres,
        ...this.armures.brassards,
        ...this.armures.boucliers,
        // Ajoutez d'autres types d'armures ici...
      ];
      return Math.ceil(totalArmures.length / this.itemsPerPage);
    },
    paginatedArmures() {
      const totalArmures = [
        ...this.armures.casques,
        ...this.armures.plastrons,
        ...this.armures.jambieres,
        ...this.armures.brassards,
        ...this.armures.boucliers,
        // Ajoutez d'autres types d'armures ici...
      ];
      const start = (this.pageArmure - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return totalArmures.slice(start, end);
    },
  },
  methods: {
    ajouterAuPanier(type, item) {
      if (item) {
        this.panier.push({ ...item, type });
      }
    },
    retirerDuPanier(index) {
      this.panier.splice(index, 1);
    },
    calculerTotal() {
      return this.panier.reduce((total, item) => total + item.prix, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: 1px solid #ffffff52;
  color: white;
  border-radius: 3px;
  padding: 9px;
}
.shop-section {
  margin: 20px 0;
}
.arme-container,
.munition-container,
.armure-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  b {
    font-weight: 100;
    font-size: 16px;
  }
  .name {
    font-weight: bold;
    font-size: 22px;
    color: #ff872f;
  }
}
.arme-card,
.munition-card,
.armure-card {
  border-bottom: 1px solid #6580bf;
  border-right: 1px solid #6b4d28;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 330px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.pagination {
  margin: 10px 0 40px 0;
  text-align: center;

  button {
    height: 50px;
    width: 50px;
    font-size: 20px;
    font-weight: bold;
    margin: 0 35px;
    color:#ff872f;
  }
}
button {
  margin-top: 10px;
}
p {
  display: inline-block;
  margin: 2px 20px 2px 0;
  padding: 0;
  font-weight: bold;
  min-width: 43%;
}
h3 {
  font-size: 30px;
  color: #00ff5c;
  text-align: center;
}
</style>
